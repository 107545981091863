/*
 * @Author: linghujie 391103334@qq.com
 * @Date: 2022-11-02 16:41:39
 * @LastEditors: linghujie 391103334@qq.com
 * @LastEditTime: 2022-11-25 11:15:26
 * @FilePath: \dlm-big-screen\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from "axios";
import qs from "qs";
import Vue from 'vue';
import VueCookies from 'vue-cookies'
Vue.prototype.$cookies = VueCookies;

if (process.env.NODE_ENV == 'production') {
    axios.defaults.baseURL = '' //正式
} else {
    axios.defaults.baseURL = 'http://127.0.0.0.1:48080' //'http://192.168.99.198:80/' //测试
}
//post请求头
// axios.defaults.headers.post["Content-Type"] =
//     "application/x-www-form-urlencoded;charset=UTF-8";
// console.log($cookie.get('cookie'))
// axios.defaults.headers = {
//         isToken: 'false',
//         //   'Authorization': 'Basic eHRtZWV0Onh0bWVldA==',
//     }
//设置超时
axios.defaults.timeout = 10000;

axios.interceptors.request.use(
    config => {
        if (!Vue.$cookies.get('cookie')) { // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.isToken = false;
        } else {
            config.headers.Authorization = 'Bearer' + ' ' + Vue.$cookies.get('cookie');
        }
        // config.headers['tenant-id'] = 1
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        if (response.data.code == 401) {
            // router.go(0)
            //  location.reload()
        }
        return response;
    },
    error => {
        return Promise.reject(error);
    }
);

export default function axiosApi(type, params, method) {
    var data = method == "post" ? qs.stringify(Object.assign(params)) : Object.assign(params)
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: type,
            data: data
        })
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            });
    })
};