
<template>
  <div class="echarts">
    <div
      id="map"
      :style="{ height: '100%', width: '100%' }"
      ref="myEchart"
    ></div>
  </div>
</template>

<script>
import "../utils/china.js";

export default {
  data() {
    return {
      mapChart: null,
    };
  },
  methods: {
    hadleMap(data, zoom, center) {
      this.init(data, zoom, center);
    },
    init(data, zoom, center) {
      this.mapChart = this.$echarts.init(document.getElementById("map"));

      let option = {
        geo: {
          type: "map",
          map: "china",
          roam: false,
          zoom: zoom ? zoom : 0.7,
          center: center,
          label: {
            normal: {
              show: false,
              textStyle: {
                color: "rgba(255,255,255,0.5)",
                fontSize: 12,
              },
            },
            emphasis: {
              // 对应的鼠标悬浮效果
              show: true,
              textStyle: {
                color: "rgba(255,255,255,1)",
                fontSize: 18,
              },
            },
          },
          itemStyle: {
            normal: {
              areaColor: "rgba(1,230,246,0.3)",
              borderColor: "#000000",
            },
            emphasis: {
              borderWidth: 0,
              areaColor: "rgba(1,230,246,0.5)",
              borderColor: "#ffffff;",
            },
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,0.5)", //提示框背景色
          borderWidth: 0,
          formatter: function (param) {
            //自定义tooltip内容
            var text = "";
            text += `<div style="display:flex;flex-direction:row;"><div style="background-color:#03D16D;height:auto;width:5px;margin-right:5px;"></div><div style="display:flex;flex-direction:column;"><div>${param.name}</div></div></div>`;
            return text;
          },
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            type: "scatter",
            coordinateSystem: "geo",
            animation: false,
            large: true,
            symbolSize: 4,
            largeThreshold: 50,

            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(255,255,0,1)",
              color: "rgba(255,255,0,1)",
            },
            data: [],
          },
        ],
      };
      this.mapChart.setOption(option);
    },
    addData(data) {
      let seriesData = data.map(function (itemOpt) {
        return {
          // name: `${itemOpt.snNo}/${itemOpt.location ? itemOpt.location : " "}`,
          name: itemOpt.snNo,
          value: [itemOpt.lng, itemOpt.lat],
        };
      });
      this.mapChart.appendData({
        seriesIndex: 0,
        data: seriesData,
      });
      // 添加一个缩放事件 避免追加数据时显示缺失问题
      this.mapChart.dispatchAction({
        type: "dataZoom",
        start: 20,
        end: 30,
      });
    },
  },
};
</script>

<style>
</style>